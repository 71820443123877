.b2b-registration-form
{
	@include create-main-wrapper;
    position: relative;

	&_wrapper {
		@include clearfix;
		margin: auto;
		width: 30rem;
		float: left;
		padding-left: 1.563rem;
		padding-top: 8.75rem;
    }
    
    &__title {
    	text-align:left;
    }
	@include create-row-card;
	
	&_card{
		margin-top: 0.3rem;
		margin-bottom: 0.3rem;
		padding: 1.125rem;
		padding-bottom: 0px;
		background-color: rgba(255, 255, 255, 0.8);
		height: 20.313rem;
		overflow: auto;
	};
	&_gradient {
		background-image: $background-gradient-image;
		height: 1.8rem;
	}

	&_left-radius {
		border-top-left-radius: 1.5625rem;
	}

	&_right-radius {
		border-bottom-right-radius: 1.5625rem;
	}

	&__header{
		 width: 100%;
		 color: $brand-color-2;
		 margin-bottom: 0.425rem;
		 font-size: 0.8125rem;
		 font-weight: 600;
		 text-align: left;
	}
	&__loader{
		padding-top: 5.625rem;
	}
	&__content{
		margin-bottom: calc($base-padding/2);
	}
	
	.registration-form {
		
  		@include create-row-card;
  		@include create-row-card-header;
  		@include create-card-content;
  		
  		label{
  			color: $brand-color-2;
			margin-bottom:0;	
  		}
  		
  		input , textarea {
			display: block;
			width: 100%;
			height: 2rem;
			padding: 0.375rem 0.75rem;
			font-size: 0.75rem;
			line-height: 0.75rem;
			color: $brand-color-24;
			background-color: $brand-color-23;
			background-image: none;
			border: 1px solid $brand-color-25;
			border-radius: 1.5625rem;
  		}
  		
  		select{
			  color: $brand-color-20;
			  border: 1px solid $brand-color-25;
			  border-radius: 1.5625rem;
  		}
 
  		
  		&__container{
  		   &_name-container{
  		   		float: left;
    			width: 100%;
	  		    .name-container{
	  		    		@include clearfix;
    				&__title{
    					float: left;
    					width: 20%;
    					padding-right:0.5rem;
    					min-height: 3rem;
    						&_dropdown{
    							border-radius: 0;
    							width: 100%;
    							padding-right:0;
    						}
    				}
  				}
  			}
  			&_contact-details{
  				float: left;
    			width: 100%;
  				.contact-details{
					@include clearfix;
  					&__content{
							min-height: 3rem;
							float: left;
							width: 33.33%;
							padding-right:0.5rem;
							.form-control{
								border-radius:0;
							}
							.info-icon{
								font-size: 1rem;
								color: $brand-color-2;
							}	

							input, select , textarea {
								display: block;
								width: 100%;
								height: 2rem;
								padding: 0.375rem 0.75rem;
								font-size: 0.75rem;
								line-height: 0.75rem;
								background-color: $brand-color-23;
								background-image: none;
								border: 1px solid $brand-color-25;
								border-radius: 1.5625rem;
							  }

						  }
						&__content-wide{
						min-height: 3rem;
						float: left;
						width: 50%;
						padding-right:0.5rem;
						.form-control{
							border-radius:0;
						}
						.info-icon{
							font-size: 1rem;
							color: $brand-color-2;
						}	
						}
						&__content-short{
							@include clearfix;
							float: left;
							width: 25%;
							padding-right:0.5rem;
							min-height: 2rem;

							&_field-label{
								float: left;
							}
							&_field-value{
								float: left;
								width: 20%;

								input, select , textarea {
									display: block;
									width: 100%;
									height: auto;
									padding: 0.375rem 0.75rem;
									font-size: 0.75rem;
									line-height: 0.75rem;
									color: $brand-color-24;
									background-color: $brand-color-23;
									background-image: none;
									border: 1px solid $brand-color-25;
									border-radius: 1.5625rem;
								  }
							}
						}
  				}
  			}
  			
  			&_address-details{
  				float: left;
    			width: 100%;
  				.address-details{
					@include clearfix;
  					&__content{
		  				min-height: 5.5rem;
		  				float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  					}
	  				&__city-content,&__zip-code-content{
	  					min-height: 4.75rem;
		  				float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  				}	
  				}
  			}
  			

			  &_button {
				font-size: 0.8125rem;
				padding: 0.3125rem;
				text-align: center;
				text-transform: uppercase;
				transition: 0.5s;
				background-size: 200% auto;
				color: $brand-color-23;
				font-weight: 900;
				width: 80%;
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
				transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
				cursor: pointer;
				display: inline-block;
				border-radius: 1.5625rem;
				border: none;
				width: 100%;
				margin-bottom: 0.8rem;
				background-image: $background-gradient-image;
				&:hover {
				  background-position: right center;
				}
				&:disabled {
				  background-color: lighten($brand-color-2, 20);
				  &:hover {
					background-color: lighten($brand-color-2, 20);
				  }
				  cursor: not-allowed;
				}
			  }   
 	   }
	.wrapper{
		&__error{
			color: $brand-color-1;
			font-weight:100;
			font-size: $font-size-h6;
		}
	} 	
 }
   @include custom-scroll-bar;
}


