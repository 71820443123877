.pos-footer {
  @include create-main-wrapper;
  display: flex;
  align-items: center;
  clear: both;
  padding-left: 7.188rem;

  &__wrapper {
    @include clearfix;
    width: 100%;
    flex-direction: column;
    position: relative;
    min-height: inherit;
	  justify-content: space-between;
	
	.menutext{
		color: $brand-color-31;
		font-size: 0.75rem;
	}
  }

  &__contact-details {
    display: flex;
    color: $brand-color-11;
    align-items: center;
    padding-left: 7.188rem;
    font-size: 0.75rem;
    letter-spacing:1px;
  }

  &__footer-details {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    float: left;
  }

  &_link-color {
    color: $brand-color-11;
    &:focus,
    &:hover {
      color: $brand-color-11;
      transform: scale(1.1);
    }
  }
}
