@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
@import "../../../../../stylesheets/modules/styles/commons/base/base-variables";

$brand-color-2:             #0054a6;  
$brand-color-20:             gray;  
$brand-color-23:             #fff;  
$brand-color-24:             #000;; 
$brand-color-25:             #ccc;

$brand-color-26:             #cd2534;
$brand-color-27:             #8f3254;
$brand-color-28:             #1d4a8f;
$brand-color-29:             #633b6b;

$brand-color-30:             #eee;
$brand-color-31:             #93bcf4;

$background-gradient-image: linear-gradient(to right, $brand-color-26 0%, $brand-color-27 25%, $brand-color-28 50%, $brand-color-29 100%);