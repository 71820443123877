.pos-app {
	margin: auto;
    font-weight: 400;
}
.pos-auth-content{
    background-image: url(../images/alrais-b2b-bg-banner.jpg);
    background-size: cover;	
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
}

.pos-app-content{
    overflow-y: scroll;
}
