.landing-page{

    &__frame {
        overflow: hidden;
        border: 0px;
        width: 27.25rem;
        height: 33.438rem;
        float: left;
        padding-left: 4.813rem;
    }

    &__info-frame{
        overflow: hidden;
        border: 0px;
        width: 27.396rem;
        height: 25rem;
        float: left;
        padding-left: 0.938rem;
        margin-top: 8.438rem;
    }
}