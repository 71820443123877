@use "sass:math";

.pos-reset-password-form,
.pos-login-form {
  @include create-main-wrapper;
  @include custom-scroll-bar;
  position: relative;

  &_wrapper {
    @include clearfix;
    margin: auto;
    width: 30rem;
    float: left;
    padding-left: 1.563rem;
    padding-top: 8.75rem;
  }

  &__title { 
    font-size: 2rem;
    text-align: center;
  }

  &__body {
    margin: auto;
    max-width: 37.5rem;
    text-align: center;
  }

  @include create-row-card;

  &_card {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    padding: 1.125rem;
    padding-bottom: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 20.313rem;
    overflow: auto;
  }

  &_gradient {
    background-image: $background-gradient-image;
    height: 1.8rem;
  }

  &_left-radius {
    border-top-left-radius: 1.5625rem;
  }

  &_right-radius {
    border-bottom-right-radius: 1.5625rem;
  }

  &__header {
    text-transform: none;
    color: $brand-color-2;
    margin-bottom: $base-padding*0.5;
    padding: math.div($base-padding, 6) $base-padding*0.5;
    padding-left: 0px;
    text-align: center;

    &_text {
      font-size: 2rem;
      font-weight: 700;
    }
  }
}

.reset-password__page,
.login__page {
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include custom-scroll-bar;
  &_card {
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      border-bottom: 1px solid $brand-color-2;
      margin-bottom: $base-margin*0.5;
    }
    .header_text {
      margin-right: $base-margin;
    }
  }
  &_form {
    max-width: 26.25rem;
    margin: auto;
  }
  &_input_wrapper {
    margin-bottom: 0.2rem;
    margin-top: 0.5rem;
    height: auto;

    label {
      width: 100%;
      color: $brand-color-2;
      margin-bottom: 0.425rem;
      font-size: 0.8125rem;
      font-weight: 600;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      width: 90%;
      padding: 0.5rem 1rem;
      background: none;
      font-size: 0.875rem;
      vertical-align: middle;
      line-height: normal;
      background-color: $light;
      border: 1px solid;
      border-color: $input-border-color;
      border-radius: 0px 1.5625rem 1.5625rem 0px;
      max-height: 2.125rem;
    }
  }

  &_button {
    font-size: 0.8125rem;
    padding: 0.3125rem;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: $brand-color-23;
    font-weight: 900;
    width: 80%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    display: inline-block;
    border-radius: 1.5625rem;
    border: none;
    width: 100%;
    margin-bottom: 0.8rem;
    background-image: $background-gradient-image;
    &:hover {
      background-position: right center;
    }
    &:disabled {
      background-color: lighten($brand-color-2, 20);
      &:hover {
        background-color: lighten($brand-color-2, 20);
      }
      cursor: not-allowed;
    }
  }

  &_links {
    width: 100%;  
    display: flex;
    a {
      text-transform: none;
      color: $brand-color-2;
      font-weight: 100;
      text-decoration: none;
      font-size: 0.9375rem;
      width: 100%;
      text-align: center;
      &:hover,
      &:focus {
        color: darken($brand-color-2, 10);
      }
    }
  }

  &_input-group {
    @include clearfix;
    width: 100%;
    .fa-user,
    .fa-lock,
    .fa-envelope,
    .fa-building {
      width: 10%;
    }
  }

  &_input-group-addon {
    white-space: nowrap;
    vertical-align: middle;
    padding: 9px 10px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border: 1px solid $brand-color-25;
    border-radius: 1.5625rem 0rem 0rem 1.5625rem;
    float: left;
    color: $brand-color-2;
    background-color: $brand-color-30;
  }

  &_registration-message {
    color: $brand-color-2;
  }

  &_register-now {
    @include make-button($light, $brand-color-1, capitalize, $light, darken($brand-color-1, 20));
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    width: 100%;
    font-weight: 700;
  }
  &_button-separator {
    margin-top: 5px;
    .login__page_button-separator-label {
      position: relative;
      z-index: 1;
      text-align: center;
      &:before {
        border-top: 1px solid $brand-color-2;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
      }
      span {
        background: $brand-color-4;
        padding: 0 0.938rem;
      }
    }
  }
}

.wrapper {
  &__error {
    color: rgba(192, 0, 0, 1);
    font-weight: 100;
    font-size: $font-size-h6;
  }
  &__password-help-text{
	font-size: 0.75rem;
	color: var(--header-font-color);
  }
}
