@mixin custom-scroll-bar {
  ::-webkit-scrollbar {
    overflow-y: auto;
	  height:0.5rem;
    width: 5px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $brand-color-24;
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: $brand-color-24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $brand-color-24;
  }
}