@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-refund-list/pos-refund-list";
.pos-refund-list{
	.headers{	
		&__bkg-desc{
    		width: 15%; 
    	}
    	&__trans-desc{
    		width: 20%; 
    	}
    	&__itinerary-info{
    		width: 20%; 
    	}
    	&__pax-info{
    		width: 30%;
    	}
		&__user-name{
			width: 15%;
		}
	}
	.refund-list{
		&__refund-dtls{
		     .bkg-dtl{
				&__bkg-reference-dtl{
			    		width: 15%;
				}
				&__date{
				    width: 20%;
				}
				&__product-desc{
				    width: 20%;
				}
				&__pax-info{
				    width: 30%;
				}
				&__booking-user-name{
					width: 15%;
					float: left;
					padding-left: 5px;
				}
		}
	  }
	}
}